<template>
    <CollapseCard
        title = "Semester Settings"
        icon  = "cog"
    >
        <b-skeleton-wrapper
            :loading="loading"
        >
            <template #loading>
                <b-container class="m-4">
                    <b-skeleton></b-skeleton>
                </b-container>
            </template>
            <b-form-group
                label="Date Available"
            >
                <b-input-group>
                    <b-input-group-prepend>
                        <b-input-group-text>
                            <font-awesome-icon icon="calendar"></font-awesome-icon>
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input v-model="semesterForm.available_date" type="datetime-local" />
                </b-input-group>
            </b-form-group>
            <b-form-group
                label="Date Due"
                class="mt-2"
            >
                <b-input-group>
                    <b-input-group-prepend>
                        <b-input-group-text>
                            <font-awesome-icon icon="calendar"></font-awesome-icon>
                        </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input v-model="semesterForm.due_date" type="datetime-local" />
                </b-input-group>
            </b-form-group>
            <b-checkbox v-model="semesterForm.required" class="mt-2">Required before registration</b-checkbox>
            <b-checkbox v-model="semesterForm.pinned" class="mt-2">Pin to Home</b-checkbox>
            <div class="text-center mt-2">
                <button
                    @click="saveSemesterForm()"
                    class="sc-btn pill"
                >
                    <font-awesome-icon icon="save"></font-awesome-icon>
                    Save
                </button>
            </div>
        </b-skeleton-wrapper>
    </CollapseCard>
</template>
<script>
import { mapState } from "vuex"
import CollapseCard from "@/components/CollapseCard.vue"

export default {
    props: [ "form_id" ],
    components: { CollapseCard },
    data() {
        return {
            loading     : false,
            semesterForm: {
                available_date : null,
                due_date       : null,
                pinned         : false,
                required       : false
            }
        };
    },
    mounted() {
        this.getSemesterForm();
    },
    methods: {
        getSemesterForm() {
            this.loading = true;
            this.$api.get(
                "/api/forms/admin/fetch-semester-form",
                { params: { form_id: this.form_id } }
            ).then(({ data }) => {
                if (data.record) Object.assign(this.semesterForm, data.record);
                this.loading = false;
            });
        },
        saveSemesterForm() {
            this.$api.post(
                "/api/forms/admin/save-semester-form",
                {
                    semester_id : this.semester.id,
                    form_id     : this.form_id,
                    ...this.semesterForm
                }
            ).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title           : (data.success) ? "Success!" : "Error!",
                    variant         : (data.success) ? "success"  : "danger",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
            });
        }
    },
    computed: mapState(["semester"])
}
</script>