<template>
    <ShareLink
        title="Get form link"
        :link="formUrl"
        :filename="filename"
    />
</template>
<script>
import { mapState } from "vuex"
import ShareLink from '@/components/share/ShareLink.vue'

export default {
    components: { ShareLink },
    props: ["form_id"],
    computed: {
        formUrl() {
            return `${this.group.group_base_url || window.location.host}/form/${this.form_id}`;
        },
        filename() {
            return `${this.group.group_name}-form-${this.form_id}-qr-code.png`;
        },
        ...mapState(["group"])
    }
}
</script>