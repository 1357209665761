<template>
    <div class="mb-4">
        <b-card
            class="text-center"
        >
            <b-button
                variant="link"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                class="float-left mr-2"
            >
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </b-button>
            <h5 class="my-2">
                Settings for "{{ form.title }}"
            </h5>
            <h6>
                <span v-if="saving.length == 0" class="text-success h6">
                    Last Saved on {{ $moment(form.date_modified).format("LLLL") }}
                </span>
                <span v-else class="text-primary h6">
                    <b-spinner small class="mr-2"></b-spinner> Saving
                </span>
            </h6>
        </b-card>
        <b-skeleton-wrapper
            :loading="loading"
        >
            <template #loading>
                <b-container>
                    <b-skeleton></b-skeleton>
                    <b-skeleton></b-skeleton>
                    <b-skeleton></b-skeleton>
                </b-container>
            </template>
            <ShareForm
                :form_id="id"
            />
            <SemesterFormSettings
                v-if="semester.id"
                :form_id="id"
            />
            <!--<FormWebhookSettings></FormWebhookSettings>-->
            <f2-form
                class="mt-4"
                :key="loading"
                :noNumbers="true"
                :fields="meta_fields"
                :prefill="form"
                @onButtonClick="onClick"
            ></f2-form>
            <!--<CollapseCard
                title = "Access Settings"
                icon  = "unlock"
            ></CollapseCard>
            <CollapseCard
                title = "Assigned"
                icon  = "users"
            ></CollapseCard>-->
        </b-skeleton-wrapper>
    </div>
</template>
<script>
import { mapState }         from 'vuex';
import F2Form               from "@/components/form/Form.vue"
import SemesterFormSettings from "./SemesterFormSettings.vue"
import ShareForm            from './ShareForm.vue';
/* import FormWebhookSettings from './FormWebhookSettings.vue'; */

export default {
    props: [ "id", "form" ],
    components: { F2Form, SemesterFormSettings, ShareForm, /* FormWebhookSettings */ },
    data() {
        return {
            loading    : false,
            last_saved : null,
            meta_fields: [
                { label: "Title",                   type: "input",    name: "title" },
                { label: "Description",             type: "textarea", name: "description" },
                { label: "Submit Limit",            type: "input",    name: "submit_limit", subtype: "number" },
                { label: "Response Submit Limit",   type: "input",    name: "response_submit_limit", subtype: "number" },
                { label: "Public",                  type: "check",    name: "is_public" },
                { label: "Can Edit Responses",      type: "check",    name: "can_edit_response" },
                { label: "Can Delete Responses",    type: "check",    name: "can_delete_response" },
                { label: "Show Form Responses",     type: "check",    name: "show_form_responses" },
                { label: "Custom Submit Message",   type: "textarea", name: "custom_submit_message" },
                { label: "Custom Submit Redirect",  type: "input",    name: "custom_submit_redirect" },
                { label: "Save Form Settings",      type: "button",   options: {
                    onclick: "submit"
                }}
            ],
            saving: [],
            jobId : 0
        };
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        getForm() {
            this.loading = true;
            this.$api.get(
                `/api/forms/admin/${this.id}/fetch`
            ).then(({ data }) => {
                var form     = data.form;
                this.form    = Object.assign(this.form, data.form);
                /*Object.keys(form).forEach((key) => {
                    this.form[key] = form[key];
                });*/
                this.loading = false;
                console.log(data.form);
                if (form.fields)
                {
                    form.fields.push({ id: 0, type: "button", label: "Submit", options: { working: false } });
                    this.fields = form.fields;
                }
                this.last_saved = this.form.date_modified;
            });
        },
        saveForm(model) {
            var currentJobId = this.jobId++;
            this.saving.push(currentJobId);
            this.$api.post(
                `/api/forms/admin/${this.id}/save`,
                Object.fromEntries(this.meta_fields.map((field) => [field.name, model[field.name]]))
            ).then(({ data }) => {
                //Object.assign(this.form, );
                this.$emit("update-record", data.form);
                // if (response.data.refreshModel);
                /*this.form    = {};
                Object.keys(form).forEach((key) => {
                    this.form[key[0].toUpperCase() + key.slice(1)] = form[key];
                });
                console.log(data.form);
                //this.fields     = (form.fields || []);*/
                setTimeout(() => {
                    this.saving.splice(this.saving.indexOf(currentJobId), 1);
                    this.$bvToast.toast(data.message, {
                        title           : (data.success) ? "Success!" : "Error!",
                        variant         : (data.success) ? "success"  : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                }, 100);
            });
        },
        onClick(field, model) {
            if (field && field.options) {
                if (field.options.onclick == "submit") {
                    this.saveForm(model);
                }
            }
        }
    },
    computed: mapState(["semester"])
}
</script>